.App {
  font-family: sans-serif;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 2px;
  cursor: pointer;
}
